<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="600"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="pl-0 mb-5">
            <h2>{{ formTitle }}</h2>
          </v-card-title>

          <!-- タブ -->
          <v-tabs
            class="flex-grow-0"
            v-model="selectedTab"
            centered grow show-arrows
            color="primary"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.id"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items
            class="pt-5"
            v-model="selectedTab"
            continuous
          >
            <!-- 基本情報 -->
            <v-tab-item>
              <v-form ref="form-basic">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.trim="customer.name"
                      label="名前"
                      required
                      counter="30"
                      :rules="[valiRules.required, valiRules.max30]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      disabled
                      :value="customer.customer_id"
                      label="会員番号"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      v-model.trim="customer.phone"
                      label="電話番号"
                      counter="20"
                      :rules="[valiRules.phone, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="isCreate"
                      :readonly="!isCreate"
                      :value="customer.line_id"
                      label="LINE識別子（手入力不可）"
                      @click="alertLineId"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="customer.note"
                      filled
                      rows="3"
                      counter="300"
                      label="メモ"
                      :rules="[valiRules.max300]"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="ml-3">
                  <v-col cols="12">
                    <v-switch class="mt-0"
                      v-model="customer.is_black"
                      inset
                      hide-details
                      label="ブラック認定"
                    ></v-switch>
                  </v-col>
                  <!-- <v-col cols="6">
                    <v-switch
                      v-model="customer.is_nonmember"
                      inset
                      hide-details
                      label="退会済み"
                    ></v-switch>
                  </v-col> -->
                </v-row>

                <v-card-actions class="mt-2">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedBasic"
                    color="primary"
                    @click="formBasicInfoSubmitted"
                  >
                    {{ formButton }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- 顧客グループ -->
            <v-tab-item>
              <v-overlay
                absolute
                opacity=0.8
                :value="!customer.line_id"
              >
                <icon-info icon="alert" class="ml-n1 mt-3 mb-9" :square="true">
                  こちらはLINE指定配信をする際に使われるので、LINE識別子の無い顧客情報ではご利用できません。
                </icon-info>
              </v-overlay>
              <icon-info icon="lightbulb-on-outline" class="ml-n1 mt-3 mb-9" :square="true">
                グループ分けした顧客へLINE等の指定配信をする際に使われます。<br />
                作成したグループは「店舗基本設定」→「顧客グループ」メニューで確認できます。
              </icon-info>
              <v-form ref="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      class="mt-0"
                      v-model="selectedGroups"
                      label="所属する顧客グループを選ぶ"
                      multiple chips
                      :items="customerGroups"
                      item-value="customer_group_id"
                      item-text="group_name"
                      item-color="primary"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    :disabled="!changedGroups"
                    color="primary"
                    @click="formCustomerGroupSubmitted"
                  >
                    {{ formButton }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

            <!-- 利用履歴作成 -->
            <v-tab-item>
              <v-overlay
                absolute
                opacity=0.8
                :value="isCreate"
              >
                <icon-info icon="alert" class="ml-n1 mt-3 mb-9" :square="true">
                  ダミー利用履歴の作成は顧客情報を登録してからご利用できます。
                </icon-info>
              </v-overlay>
              <v-form ref="form-booking">
                <v-row class="mt-0">
                  <v-col cols="4" sm="3">
                    <v-text-field
                      v-model="dummyBookingCount"
                      type="number"
                      min=1 max=10
                      label="利用回数"
                      required
                      :rules="[valiRules.required, valiRules.max10num]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <icon-info icon="lightbulb-on-outline" :square="true">
                      ダミーの利用履歴を作成します。<br />
                      新規で作成した顧客情報の利用履歴を増やしたい時にご利用ください。
                    </icon-info>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                    v-model="customer.customer_id"
                    disabled
                    label="会員番号"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                    v-model="customer.name"
                    disabled
                    label="ご予約名"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-2">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    @click="formDummyBookingRecordSubmitted"
                  >
                    作成
                  </v-btn>
                  <btn-tip
                    class="ml-2"
                    tip="ダミーの利用履歴を全削除します" label="削除"
                    text
                    color="primary"
                    @click="deleteDummyBookingRecords"
                  ></btn-tip>
                  <v-btn
                    text
                    color="primary"
                    @click="close"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-tab-item>

          </v-tabs-items>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules, ConvertPhone } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'

export default {
  components: {
    'icon-info': IconInfo,
    'btn-tip': BtnWithTip,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      customer: {},
      selectedGroups: [],
      customerGroups: [],
      dummyBookingCount: 1,
      selectedTab: 0,
      isPhoneAlerted: false,
      openForm: false,
      changedBasic: false,
      changedGroups: false,
      formType: null,
      comeBack: {},
      submitCallback: null,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      tabs: [
        {id: 0, name: '基本情報'},
        {id: 1, name: '顧客グループ'},
        {id: 2, name: '利用履歴作成'},
      ],
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    formTitle() {
      return this.formType === 'create' ? '新規顧客登録' : '顧客情報更新'
    },
    formButton() {
      return this.formType === 'create' ? '登録' : '更新'
    },
    isCreate() {
      return this.formType === 'create' ? true : false
    }
  },

  watch: {
    customer: {
      handler: function(now, old) {
        if (Object.keys(old).length && now.customer_id === old.customer_id) {
          this.changedBasic = true
        }
      },
      deep: true
    },
    selectedGroups: function() {
      this.changedGroups = true
    },
    selectedTab: function(now, old) {
      if (old !== now) this.changedGroups = false
    },
    'customer.phone': function(now, old) {
      if (!now) return
      if (!this.isCreate && now !== old && this.changedBasic && !this.isPhoneAlerted) {
        alert('*電話番号を変えると以前の電話番号で登録された利用履歴が結びつかなくなる可能性があります')
        this.isPhoneAlerted = true
      }
    },
    openForm: function(now, old) {
      if (now !== old) {
        this.changedBasic = false
        this.changedGroups = false
      }
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getCustomerGroups()
  },

  //**************************************************
  // メソッド
  //**************************************************
  methods: {
    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack

      if (this.isCreate) {
        this.customer = {
          customer_id: '自動生成されます',
          is_nonmember: false,
          member_type: 'shop',
          name: '',
          phone: '',
          line_id: '',
          line_avator: '',
          is_black: false,
          note: ''
        }
      } else {
        this.customer = { ...handdownData.updateData }

        //顧客グループの取得
        this.setCustomerGroup(this.customer.customer_id)
      }
      this.openForm = true
    },

    close() {
      this.selectedGroups.length = 0
      this.selectedTab = 0
      this.submitCallback = null
      this.isPhoneAlerted = false
      this.changedBasic = false
      this.openForm = false
    },

    //
    // API req: 顧客グループ取得
    //
    getCustomerGroups() {
      return this.adminApi.getReqWithAuth('customer-group/').then( groups => {
        if (!groups || !groups.length) return

        this.customerGroups = groups
      })
    },

    setCustomerGroup(customerId) {
      this.adminApi.getReqWithAuth('customer/' + customerId + '/group/').then( groups => {
        groups.map( row => this.selectedGroups.push(row.group_id) )
      })
    },

    alertLineId() {
      this.snackbar = {...{color:'info', message: 'LINE識別子は間違えると配信エラーになるので変更できません', open: true}}
    },

    //**************************************************
    //**************************************************
    //                    各サブミット
    //**************************************************
    //**************************************************
    //基本情報
    //**************************************************
    formBasicInfoSubmitted() {
      if (!this.$refs['form-basic'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const method = this.isCreate ? 'POST' : 'PUT'
      const apiPartial = this.isCreate ? 'customer/create/' : 'customer/update/' + this.customer.customer_id

      const formData = new FormData()
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('customer_id', this.isCreate ? '' : this.customer.customer_id)
      formData.append('is_nonmember', this.customer.is_nonmember)
      formData.append('member_type', this.customer.member_type)
      formData.append('name', this.customer.name)
      formData.append('phone', ConvertPhone(this.customer.phone))
      formData.append('line_id', this.customer.line_id)
      formData.append('line_avator', this.customer.line_avator)
      formData.append('is_black', this.customer.is_black)
      formData.append('note', this.customer.note)

      this.adminApi.apiReqWithData(method, apiPartial, formData).then( response => {
        this.comeBack.update = 'basic'
        this.submitCallback(response, this.comeBack)
        this.formType = 'update'
        this.changedBasic = false
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //顧客グループ
    //**************************************************
    formCustomerGroupSubmitted() {
      if (this.isCreate) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningCreateBasicInfoFirst, open: true}}
        return
      }
      if (!this.$refs['form-group'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'customer/' + this.customer.customer_id + '/update/group/'
      const payload = JSON.stringify(this.selectedGroups)

      this.adminApi.apiReqWithData('POST', apiPartial, payload).then( response => {
        response.map( row => {
          const target = this.customerGroups.find( group => group.customer_group_id === row.group_id )
          if (target !== undefined) row.group_name = target.group_name
        })

        this.comeBack.update = 'group'
        this.submitCallback(response, this.comeBack)
        this.changedGroups = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //利用履歴作成
    //**************************************************
    formDummyBookingRecordSubmitted() {
      if (!this.$refs['form-booking'].validate() || this.dummyBookingCount < 1) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const apiPartial = 'booking/create/dummy/'
      const payload = JSON.stringify({
        customer_id: this.customer.customer_id,
        booker_name: this.customer.name,
        booking_count: Number(this.dummyBookingCount)
      })

      this.adminApi.apiReqWithData('POST', apiPartial, payload).then( response => {
        this.comeBack.update = 'booking'
        this.submitCallback(response, this.comeBack)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //利用履歴削除
    //**************************************************
    deleteDummyBookingRecords() {
      this.loading = true

      const apiPartial = 'booking/delete/dummy/'
      const payload = JSON.stringify({ customer_id: this.customer.customer_id })

      this.adminApi.apiReqWithData('DELETE', apiPartial, payload).then( response => {
        this.comeBack.update = 'deleteBooking'
        this.submitCallback(response, this.comeBack)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
</style>
