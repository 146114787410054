<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="600"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="pl-0 mb-3">
            <h2>LINEメッセージ送信</h2>
          </v-card-title>

          <v-form ref="form">
            <!-- <v-row>
              <v-col>
                <v-card class="preview pa-3 grey lighten-4"
                  max-height="200"
                >
<pre id="push-message">{{ customer.name }}様へ店舗からメッセージです。

『{{ messageContent }}』 </pre>
                </v-card>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="messageContent"
                  filled
                  min-min-height="170"
                  counter="1000"
                  placeholder="こちらにメッセージを入力してください"
                  required
                  :rules="[valiRules.required, valiRules.max1000]"
                ></v-textarea>
              </v-col>
            </v-row>

            <icon-info icon="lightbulb-on-outline" class="ml-n3 mt-0 mb-3" :square="true">
              記入した内容がお客様のLINEにそのまま送信されます。
            </icon-info>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="formSubmitted"
              >
                送信
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute top
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ValidationRules } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    'icon-info': IconInfo,
  },

  data() {
    return {
      customer: {},
      messageContent: '',
      openForm: false,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    };
  },

  methods: {
    open(handdownData) {
      this.customer = { ...handdownData.targetData }
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack
      this.openForm = true
    },

    close() {
      this.messageContent = ''
      this.submitCallback = null
      this.loading = false
      this.openForm = false
    },

    formSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      if (this.submitCallback) {
        this.comeBack.cbOnSccess = this.close
        this.submitCallback(this.messageContent, this.comeBack)
      }
    }
  }
}
</script>

<style scoped>
.preview {
  overflow-y: scroll;
}
</style>
