<template>
  <div>
    <v-tooltip
      bottom
      open-delay="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          :disabled="$attrs.disabled"
          :small="$attrs.small"
          :x-small="$attrs.xsmall"
          :large="$attrs.large"
          :outlined="$attrs.outlined"
          :text="$attrs.text"
          :fab="$attrs.fab"
          :color="$attrs.color"
          :elevation="$attrs.elevation || 0"
          @click="$emit('click')"
          v-bind="attrs"
          v-on="on"
        >
          {{ $attrs.label }}<v-icon :small="$attrs.ismall">{{ mdiIcon($attrs.icon) }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $attrs.tip }}</span>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,

  components: {
  },

  props: {
  },

  data() {
    return {
    };
  },

  computed: {
    mdiIcon() {
      return name => 'mdi-' + name
    }
  },

  created() {
  }
};
</script>

<style scoped>
  .tiptip {
    display: none;
  }
</style>
