<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="700"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="pl-0 mb-5">
            <h2>顧客情報を統合</h2>
          </v-card-title>

          <icon-info icon="lightbulb-on-outline" class="ml-n3 mb-5" :square="true">
            すでに顧客情報・利用履歴のある顧客が新規でLINE友だち追加した際などに有効です。
          </icon-info>

          <!-- 統合フィールド -->
          <v-card class="mt-10 px-5 pb-7"
            elevation=1
          >
            <v-row>
              <v-col cols="9" class="pb-0">
                <v-card-text class="px-0">
                  統合するもう1つの顧客情報を会員番号で指定してください。
                </v-card-text>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-text-field
                  v-model.trim="secondCustomerId"
                  type="number" min=1
                  label="会員番号"
                  hide-details
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="firstInfo.name"
                  readonly
                  label="名前"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end justify-center">
                <v-switch class="mt-0"
                  :value="!!firstInfo.name"
                  :disabled="!secondInfo.name"
                  color="gray"
                  hide-details
                  @change="mergedInfo.name = $event ? secondInfo.name : firstInfo.name"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="secondInfo.name"
                  readonly
                  label="名前"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="firstInfo.customer_id"
                  readonly
                  label="会員番号"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end justify-center">
                <v-switch class="mt-0"
                  :value="!!firstInfo.customer_id"
                  :disabled="!secondInfo.customer_id"
                  color="gray"
                  hide-details
                  @change="mergedInfo.customer_id = $event ? secondInfo.customer_id : firstInfo.customer_id"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="secondInfo.customer_id"
                  readonly
                  label="会員番号"
                  hide-details
                  required
                  :rules="[valiRules.required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="firstInfo.phone"
                  readonly
                  label="電話番号"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end justify-center">
                <v-switch class="mt-0"
                  :value="!!firstInfo.phone"
                  :disabled="!secondInfo.phone"
                  color="gray"
                  hide-details
                  @change="mergedInfo.phone = $event ? secondInfo.phone : firstInfo.phone"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="secondInfo.phone"
                  readonly
                  label="電話番号"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="firstInfo.line_id"
                  readonly
                  label="LINE識別子"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end justify-center">
                <v-switch class="mt-0"
                  :value="!!firstInfo.line_id"
                  :disabled="!secondInfo.line_id"
                  color="gray"
                  hide-details
                  @change="mergedInfo.line_id = $event ? secondInfo.line_id : firstInfo.line_id"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="secondInfo.line_id"
                  readonly
                  label="LINE識別子"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <v-textarea
                  :value="firstInfo.note"
                  readonly filled rows="2"
                  label="メモ"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end justify-center">
                <v-switch class="mt-0"
                  :value="!!firstInfo.note"
                  :disabled="!secondInfo.note"
                  color="gray"
                  hide-details
                  @change="mergedInfo.note = $event ? secondInfo.note : firstInfo.note"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="5">
                <v-textarea
                  :value="secondInfo.note"
                  readonly filled rows="2"
                  label="メモ"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="firstInfo.use_count"
                  readonly
                  label="利用履歴"
                  suffix="回"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end justify-center">
                <p>合算</p>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  :value="secondInfo.use_count"
                  readonly
                  label="利用履歴"
                  suffix="回"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- 統合後情報 -->
          <v-form ref="form"
            class="mt-7"
          >
            <h4 class="mb-2">
              統合後の顧客情報
            </h4>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model.trim="mergedInfo.name"
                  label="名前"
                  required
                  counter="30"
                  :rules="[valiRules.required, valiRules.max30]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  disabled
                  :value="mergedInfo.customer_id"
                  label="会員番号"
                  required
                  :rules="[valiRules.required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="pt-0">
                <v-text-field
                  v-model.trim="mergedInfo.phone"
                  label="電話番号"
                  counter="20"
                  :rules="[valiRules.phone, valiRules.max20]"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-text-field
                  disabled
                  :value="mergedInfo.line_id"
                  label="LINE識別子（手入力不可）"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="mergedInfo.note"
                  filled
                  rows="3"
                  counter="300"
                  label="メモ"
                  :rules="[valiRules.max300]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row no-gutters class="ml-3">
              <v-col cols="12">
                <v-switch class="mt-0"
                  v-model="mergedInfo.is_black"
                  color="primary"
                  inset hide-details
                  label="ブラック認定"
                ></v-switch>
              </v-col>
            </v-row>

            <v-card-actions>
              <p class="text-caption">*選択しなかった方の会員番号は統合されて無くなります</p>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="formMergeSubmitted"
              >
                統合&登録
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ValidationRules } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    'icon-info': IconInfo,
  },

  props: {
    shopData: {
      type: Object,
      required: true
    },
    customers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      mergedInfo: {},
      firstInfo: {},
      secondInfo: {},
      secondCustomerId: 0,
      timerId: null,
      submitCallback: null,
      comeBack: {},
      loadingCustomer: false,
      openForm: false,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    }
  },

  computed: {
  },

  watch: {
    secondCustomerId: function(now) {
      if (this.timerId) clearTimeout(this.timerId)
      if (!now || now < 1) return

      this.loadingCustomer = true

      const vm = this
      this.timerId = setTimeout(function() {
        //1秒待って顧客情報取得
        const target = vm.customers.find( customer => customer.customer_id == now )
        if (target !== undefined) {
          vm.secondInfo = target
        } else {
          vm.snackbar = {...{color:'warning', message: $literals.MESSAGE.infoNoCustomerExists, open: true}}
        }
        vm.loadingCustomer = false
        vm.timerId = null
      }, 1000)
    }
  },

  //**************************************************
  // メソッド
  //**************************************************
  methods: {
    open(handdownData) {
      this.submitCallback = handdownData.submitCallback
      this.firstInfo = handdownData.mergeData
      this.mergedInfo = {...handdownData.mergeData}
      this.comeBack = handdownData.comeBack
      this.openForm = true
    },

    close() {
      this.mergedInfo = {}
      this.firstInfo = {}
      this.secondInfo = {}
      this.secondCustomerId = 0
      this.submitCallback = null
      this.loading = false
      this.openForm = false
    },

    //**************************************************
    //サブミット
    //**************************************************
    formMergeSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }
      if (!this.secondInfo.customer_id) {
        this.snackbar = {...{color:'warning', message: '統合する会員情報が選択されていません', open: true}}
        return
      }

      this.loading = true

      const formData = new FormData()
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('customer_id', this.mergedInfo.customer_id)
      formData.append('member_type', this.mergedInfo.line_id ? 'line' : 'shop')
      formData.append('name', this.mergedInfo.name)
      formData.append('phone', this.mergedInfo.phone)
      formData.append('line_id', this.mergedInfo.line_id)
      formData.append('note', this.mergedInfo.note)
      formData.append('is_black', this.mergedInfo.is_black)

      this.mergedInfo.groups = [...this.firstInfo.groups, ...this.secondInfo.groups]
      this.mergedInfo.use_count = this.firstInfo.use_count + this.secondInfo.use_count

      this.comeBack.mergeData = this.mergedInfo
      this.comeBack.deleteId = this.mergedInfo.customer_id == this.firstInfo.customer_id ?
                               this.secondInfo.customer_id : this.firstInfo.customer_id

      this.submitCallback(formData, this.comeBack)
    },
  }
}
</script>

<style scoped>
</style>
