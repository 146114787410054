<template>
  <v-container fluid class="content-wrap">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[
        {label:'顧客', icon:'plus-box-multiple', tip:'新規顧客追加', event:'addClicked'},
        {label:'グループ', icon:'plus-box-multiple', tip:'検索結果で配信用顧客グループを作成', event:'addGroupClicked'}
      ]"
      @addClicked="openFormRegister('create')"
      @addGroupClicked="openFormGroup()"
    >
      <template v-slot:content-info>
        登録者数：{{ customersSource.length }}名 ／ 検索結果：{{ customers.length }}名（LINE友だち{{ lineMemberCount }}名）
      </template>
    </info-bar>

    <!-- フィルター -->
    <v-row no-gutters>
      <v-expansion-panels :value="0" popout>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template>
              <v-row no-gutters justify="center">
                <v-col class="pt-1">
                  <v-fade-transition leave-absolute>
                    <span>検索条件：{{ conditions }}</span>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- デートピッカー -->
            <v-row no-gutters>
              <v-col cols="6" sm="3" class="px-2">
                <v-menu bottom
                  v-model="openPickerFrom"
                  :close-on-content-click="false"
                  transition="scroll-y-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerDeteFrom"
                      label="登録日範囲"
                      suffix="から"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pickerDeteFrom"
                    locale="ja"
                    no-title
                    :day-format="date => numericDate(date)"
                    header-color="primary"
                    event-color="primary"
                    @input="openPickerFrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" class="px-2">
                <v-menu bottom
                  v-model="openPickerTo"
                  :close-on-content-click="false"
                  transition="scroll-y-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickerDeteTo"
                      label="範囲終了"
                      suffix="まで"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pickerDeteTo"
                    locale="ja"
                    no-title
                    :day-format="date => numericDate(date)"
                    header-color="primary"
                    event-color="primary"
                    @input="openPickerTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="px-2">
                <v-text-field
                  v-model.trim="filterUseCountUp"
                  type="number" min=1
                  label="利用履歴"
                  suffix="回以上"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="px-2">
                <v-text-field
                  v-model.trim="filterUseCountDown"
                  type="number" min=1
                  suffix="回以下"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" class="px-2">
                <v-text-field
                  v-model.trim="whereCustomerId"
                  type="number" min=1
                  placeholder="会員番号"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="px-2">
                <v-text-field
                  v-model.trim="whereName"
                  placeholder="お名前"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="px-2">
                <v-text-field
                  v-model.trim="wherePhone"
                  placeholder="電話番号"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3"
                class="d-flex align-end justify-end"
                :class="$vuetify.breakpoint.xsOnly && 'mt-4'"
              >
                <v-btn
                  :disabled="loadingSearch"
                  depressed small
                  color="primary"
                  @click="openFormSearch()"
                >検索</v-btn>
                <btn-tip
                  class="ml-2"
                  tip="条件削除" icon="eraser"
                  text small
                  color="primary"
                  @click="clearFilter()"
                ></btn-tip>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row v-if="!customers.length">
      <v-col>
        <v-card flat>
          <v-card-text>
            <p>該当の顧客情報がありません。<br />
            検索条件を変えて再検索してください。</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 顧客情報カード -->
    <v-row>
      <v-col cols="12"
        :md="customers.length === 1 ? 12 : 6"
        :lg="customers.length === 1 ? 12 : 4"
        v-for="(customer, index) in customers"
        :key="customer.customer_id"
      >
        <v-card class="pb-2"
          :class="{
            unfollow: customer.is_nonmember,
            bigger: customers.length <= 2,
          }"
        >
          <v-row no-gutters>
            <!-- 名前 -->
            <v-col cols="12" class="d-flex">
              <v-card-title class="pt-5 pb-2 text-body-1 font-weight-bold">
                {{ customer.name }} 様
                <span v-if="customer.is_black" class="ml-1 red--text">(ブラック客)</span>
              </v-card-title>
              <v-spacer v-if="customers.length !== 1"></v-spacer>
              <v-sheet class="mx-5 pt-2 d-flex align-center"
                v-if="customers.length !== 1"
              >
                <!-- 利用履歴ドロップダウン -->
                <v-menu
                  bottom offset-y
                  max-height="500"
                  transition="slide-y-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="pt-0 pl-2 pr-1"
                      small outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setBookingRecords(customer)"
                    >
                      ご利用{{ customer.use_count }}回
                      <v-icon small>mdi-menu-down-outline</v-icon>
                    </v-btn>
                  </template>
                  <!-- プログレスバー -->
                  <v-progress-linear
                    :active="loadingBookingRecords"
                    :indeterminate="loadingBookingRecords"
                    absolute
                    top
                    color="primary"
                  ></v-progress-linear>
                  <!-- リスト -->
                  <v-list
                    subheader
                    max-width="300"
                  >
                    <v-subheader class="text-subtitle-1 font-weight-bold">
                      <!-- 評価アイコン -->
                      <v-icon
                        class="mr-2"
                        :color="customerGradeColor(customer)"
                      >
                        mdi-{{ customerGradeIcon(customer) }}
                      </v-icon>
                      利用履歴: {{ customer.use_count }}回
                    </v-subheader>
                    <v-list-item
                      v-for="booking in customer.bookingRecords"
                      :key="booking.booking_id"
                    >
                      <v-list-item-avatar v-if="booking.cast_id">
                        <v-img :src="castAvator(booking.cast_id)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ booking.cast_name }} {{ isHonshi(booking.is_honshi) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon class="mr-2">mdi-calendar-clock</v-icon>{{ bookingDateTime(booking) }}
                          <v-icon class="ml-3 mr-1">mdi-progress-clock</v-icon>{{ booking.course_name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- <v-list-item-icon>
                        <btn-tip
                          tip="履歴詳細" icon="card-bulleted-outline"
                          fab outlined small
                          color="primary"
                          @click="routeToBookingDetail(booking)"
                        ></btn-tip>
                      </v-list-item-icon> -->
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- 顧客情報 -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-subtitle class="pt-0 pb-2">
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-btn
                      class="pa-0"
                      text
                      :class="customer.line_id && 'green--text'"
                      @click="whereCustomerId = customer.customer_id"
                    >
                      <span>会員番号：</span>{{ zeroAddedNo(customer.customer_id) }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn
                      text class="pa-0"
                      @click="wherePhone = customer.phone"
                    >
                      <span>電話：</span>{{ customer.phone || '(未記入)' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-col>
            <v-col cols="12">
              <v-card-text class="py-0">
                <p><span>登録日：</span>{{ dateFormat(customer.created_at) }}（{{ memberType(customer.member_type) }}）</p>
              </v-card-text>
            </v-col>
            <!-- 配信グループ -->
            <v-col cols="12"
              v-if="customer.groups.length"
            >
              <v-card-text class="py-2">
                <p><span>グループ：</span>{{ customerGroups(customer.groups) }}</p>
              </v-card-text>
            </v-col>
            <v-col cols="12">
              <v-card-text>
                <v-textarea
                  outlined readonly no-resize
                  :height="customers.length > 1 ? 100 : 200"
                  label="メモ"
                  hide-details
                  :value="customer.note"
                ></v-textarea>
              </v-card-text>
            </v-col>
          </v-row>

          <!-- 利用履歴リスト -->
          <v-row no-gutters
            class="ma-5"
            v-if="customers.length === 1"
          >
            <!-- プログレスバー -->
            <v-progress-linear
              :active="loadingBookingRecords"
              :indeterminate="loadingBookingRecords"
              absolute
              top
              color="primary"
            ></v-progress-linear>
            <v-col cols="12" class="my-3 d-flex align-center">
              <h4>
                利用履歴: {{ customer.use_count }}回
              </h4>
                <v-icon
                  class="ml-2"
                  :color="customerGradeColor(customer)"
                >
                  mdi-{{ customerGradeIcon(customer) }}
                </v-icon>
            </v-col>
            <v-col cols="12"
              class="booking-record"
              v-for="booking in customer.bookingRecords"
              :key="booking.booking_id"
            >
              <v-card
                class="pa-4 my-1"
                flat
              >
                <v-row>
                  <v-col cols="6">
                    <v-card-title class="py-3 text-subtitle-1 font-weight-bold">
                      <v-row align="center">
                        <v-avatar class="mr-4"
                          v-if="booking.cast_id"
                        >
                          <v-img :src="castAvator(booking.cast_id)"></v-img>
                        </v-avatar>
                        <v-sheet class="d-flex flex-column" color="transparent">
                          <div>
                            {{ booking.cast_name }}
                            {{ isHonshi(booking.is_honshi) }}
                            {{ bookingStatus(booking.booking_status) }}
                          </div>
                          <div>
                            <v-icon class="mr-1">mdi-calendar</v-icon>{{ bookingDateTime(booking) }}
                            <v-icon class="ml-3 mr-1">mdi-progress-clock</v-icon>{{ bookingCourse(booking.course_name) }}
                          </div>
                        </v-sheet>
                      </v-row>
                    </v-card-title>
                  </v-col>
                  <v-col cols="6">
                    <v-card-text class="py-0 d-flex flex-column">
                      <div class="mb-3">
                        <v-icon class="mr-2">mdi-city-variant-outline</v-icon>{{ booking.place }}
                      </div>
                      <div>
                        <v-icon class="mr-2">mdi-note-text-outline</v-icon>{{ booking.note }}
                      </div>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <!-- スピードダイヤル -->
          <v-speed-dial class="mb-n2 mr-n2"
            v-model="customer.btn"
            absolute right open-on-hover
            :bottom="customers.length !== 1"
            :top="customers.length === 1"
            direction="left"
            transition="slide-x-reverse-transition"
          >
            <template v-slot:activator>
              <v-btn
                v-model="customer.btn"
                fab small
                color="primary"
              >
                <v-icon v-if="customer.btn">mdi-close</v-icon>
                <v-icon v-else>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <!-- ボタン -->
            <v-btn
              fab small
              color="primary"
              @click="openFormRegister('update', index)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <btn-tip
              :tip=" customer.line_id ? 'LINEを送る' : 'LINEが未登録です'"
              icon="message-text-outline"
              fab small
              elevation="7"
              color="primary"
              @click="openFormPushLine(index)"
            ></btn-tip>
            <btn-tip
              tip="別の顧客情報と統合"
              icon="account-multiple-outline"
              fab small
              elevation="7"
              color="primary"
              @click="openFormMerge(index)"
            ></btn-tip>
            <v-btn
              fab small
              color="primary"
              @click="openModalDelete(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>

        </v-card>
      </v-col>
    </v-row>

    <!-- 登録フォーム -->
    <form-register
      ref="formRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
    ></form-register>

    <!-- 顧客グループフォーム -->
    <form-group
      ref="formGroup"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      :customers="customers"
    ></form-group>

    <!-- 顧客情報統合フォーム -->
    <form-merge
      ref="formMerge"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      :customers="customersSource"
    ></form-merge>

    <!-- LINE送信フォーム -->
    <form-push-line ref="formPushLine"></form-push-line>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import 'moment/locale/ja'
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import FormPushLine from '@/components/_FormPushLine.vue'
import FormRegister from '@/components/CustomerFormRegister.vue'
import FormGroup from '@/components/CustomerFormGroup.vue'
import FormMerge from '@/components/CustomerFormMerge.vue'

export default {
  components: {
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'btn-tip': BtnWithTip,
    'form-register': FormRegister,
    'form-group': FormGroup,
    'form-merge': FormMerge,
    'form-push-line': FormPushLine,
    'modal-confirm': ModalConfirm,
    'modal-delete': ModalDelete,
    'overlay-message': OverlayMessage,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      pickerDeteFrom: '',
      pickerDeteTo: '',
      openPickerFrom: false,
      openPickerTo: false,
      customersSource: [], // 顧客レコードの元配列
      customers: [], // ↑から検索結果を格納
      customer: {bookingRecords: []},
      mergePair: [],
      castImages: [],
      conditions: '',
      filterUseCountUp: '',
      filterUseCountDown: '',
      whereCustomerId: '',
      whereName: '',
      wherePhone: '',
      modalMessage: '',
      timerId: null,
      loading: false,
      loadingSearch: false,
      loadingMessage: '',
      loadingBookingRecords: false,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    memberType() {
      return type => (type === 'line' ? 'LINE登録' : '手入力')
    },
    bookingCourse() {
      return course =>
        course.indexOf("コース") !== -1 ? course : course + "コース";
    },
    bookingStatus() {
      return state => (state === "確定" ? "" : "(" + state + ")");
    },
    dateFormat() {
      return date => {
        const yearStart = moment(new Date()).startOf('year')
        if (moment(date).isBefore(yearStart)) {
          return moment(date).format('YYYY年M月D日')
        } else {
          return moment(date).format('M月D日')
        }
      }
    },
    datetimeFormat() {
      return datetime => moment(datetime).format('YYYY年M月D日 HH:mm')
    },
    numericDate() {
      return date => moment(date).format('D')
    },
    zeroAddedNo() {
      return no => ('000' + no).slice(-4)
    },
    isHonshi() {
      return flag => (flag ? '🈯' : '')
    },
    lineMemberCount() {
      let count = 0
      this.customers.map( row => {
        if (row.line_id) count++
      })
      return count
    },
    customerGroups() {
      return groupArray => {
        let str = ''
        groupArray.map( (record, index) => str += record.group_name + (index !== (groupArray.length - 1) ? ', ' : '') )
        return str
      }
    },
    bookingDateTime() {
      return bookingData => {
        if (bookingData.cast_id > 0) return this.dateFormat(bookingData.start_at)
        else return 'ダミー履歴'
      }
    },
    castAvator() {
      return castId => {
        if (!this.castImages.length) return
        const cast = this.castImages.find( castImage => castImage.cast_id === castId )
        return cast !== undefined ? cast.image_url : ''
      }
    },
    customerGradeIcon() {
      return customer => {
        const len = customer.use_count
        if (len >= 10) return 'emoticon-kiss-outline'
        else if (len >= 5) return 'emoticon-excited-outline'
        else if (len > 0) return 'emoticon-happy-outline'
        else return 'emoticon-neutral-outline'
      }
    },
    customerGradeColor() {
      return customer => {
        const len = customer.use_count
        if (len >= 10) return 'pink'
        else if (len >= 5) return 'yellow'
        else if (len > 0) return 'green'
        else return 'grey'
      }
    },
  },

  //***************************************************
  //ウォッチ
  //***************************************************
  watch: {
    customers: function(now) {
      if (now.length === 1) this.setBookingRecords(now[0])
    },
    // wherePhone: function(now) {
    //   if (now === null || now.length === 0) {
    //     this.customers = this.customersSource
    //   } else if (now && now.length >= 3) {
    //     this.customers = this.customersSource.filter( customer => customer.phone.indexOf(now) > -1 )
    //   }
    // },
  },

  //***************************************************
  //ルートガード
  //***************************************************
  beforeRouteUpdate(to, from, next) {
    //顧客データ取得
    this.getCustomers()

    next()
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.snackbar = {...{color:'success', message: $literals.MESSAGE.infoSearchCondition, open: true}}

      if (this.$route.query.customer_id > 0) this.whereCustomerId = this.$route.query.customer_id
      if (this.$route.query.phone) this.wherePhone = this.$route.query.phone
      if (this.$route.query.name) this.whereName = this.$route.query.name

      this.loadingSearch = true

      this.getData()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => {
        if (this.$route.query.customer_id > 0 || this.$route.query.phone || this.$route.query.name) {
          this.filterCustomers()
        }
        this.loadingSearch = false
      })
    }
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    //
    // 初期データ取得
    //
    async getData() {
      await this.getCustomers()

      await Promise.all([
        this.setCustomerGroups(),

        this.adminApi.getReqWithAuth('cast/main-image/').then( results => {
          if (!results || !results.length) return
          this.castImages = results
        }),
      ])
    },

    //検索クリック
    openFormSearch() {
      if (!this.pickerDeteFrom && !this.pickerDeteTo && !this.filterUseCountUp && !this.filterUseCountDown &&
          !this.whereCustomerId && !this.whereName && !this.wherePhone
      ) {
        this.modalMessage = '条件を指定しない全検索は情報量が多い場合ブラウザが重くなる可能性があります。<br /><br />続行しますか？'

        const modalHanddown = {
          yesCallback: this.filterCustomers,
          buttonLabel: '続行',
        }
        this.$refs.modalConfirm.open(modalHanddown)
      } else {
        this.filterCustomers()
      }
    },

    //新規作成＆更新クリック
    openFormRegister(type, index) {
      const formHanddown = {
        formType: type,
        updateData: this.customers[index],
        submitCallback: this.updateCustomer,
        comeBack: { index: index }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //グループ作成クリック
    openFormGroup() {
      if (!this.conditions) {
        this.snackbar = {...{color:'warning', message: 'グループ分けしたい顧客を条件で検索してください', open: true}}
        return
      }
      if (!this.lineMemberCount) {
        this.snackbar = {...{color:'info', message: 'LINE友だちが0名なので配信対象がいません', open: true}}
        return
      }

      const formHanddown = {
        conditions: this.conditions
      }
    this.$refs.formGroup.open(formHanddown)
    },

    //削除クリック
    openModalDelete(index) {
      this.modalMessage = '<p>「' + this.customers[index].name + '」様の情報を削除してよろしいですか？</p>'

      const modalHanddown = {
        submitCallback: this.deleteCustomer,
        comeBack: { index: index }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //LINE送信クリック
    openFormPushLine(index) {
      if (!this.customers[index].line_id) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.warningNoLineId, open: true}}
        return
      }

      const formHanddown = {
        targetData: this.customers[index],
        submitCallback: this.pushLineMessage,
        comeBack: { index: index }
      }
      this.$refs.formPushLine.open(formHanddown)
    },

    //顧客情報統合クリック
    openFormMerge(index) {
      const formHanddown = {
        mergeData: this.customers[index],
        submitCallback: this.mergeCustomers,
        comeBack: { index: index }
      }
      this.$refs.formMerge.open(formHanddown)
    },

    //フィルター
    filterCustomers() {
      this.loadingMessage = "顧客データ検索中・・・"
      this.loading = true

      if (this.timerId) clearTimeout(this.timerId)
      const vm = this
      this.timerId = setTimeout(function() {
        vm.customers = vm.customersSource.filter( customer => {
          if (!vm.pickerDeteFrom && !vm.pickerDeteTo && !vm.filterUseCountUp && !vm.filterUseCountDown &&
              !vm.whereCustomerId && !vm.whereName && !vm.wherePhone
          ) {
            return true
          } else {
            if (vm.pickerDeteFrom && moment(customer.created_at).isBefore(vm.pickerDeteFrom)) return false
            if (vm.pickerDeteTo && moment(customer.created_at).isAfter(vm.pickerDeteTo)) return false

            if (!!vm.filterUseCountUp && customer.use_count < vm.filterUseCountUp) return false
            if (!!vm.filterUseCountDown && customer.use_count > vm.filterUseCountDown) return false

            if (vm.wherePhone && customer.phone.indexOf(vm.wherePhone) === -1) return false
            if (vm.whereName && customer.name.indexOf(vm.whereName) === -1) return false
            if (vm.whereCustomerId && customer.customer_id !== Number(vm.whereCustomerId)) return false

            return true
          }
        })

        vm.loading = false
        vm.timerId = null
      }, 0)

      this.$refs.modalConfirm.close()

      this.conditions = ''
      if (this.whereCustomerId) this.conditions += '会員番号＝' + this.whereCustomerId
      if (this.whereName) this.conditions += (this.conditions && '／') + 'お名前＝' + this.whereName
      if (this.wherePhone) this.conditions += (this.conditions && '／') + '電話番号＝' + this.wherePhone

      if (this.pickerDeteFrom) this.conditions += (this.conditions && '／') + '登録日＝' + this.dateFormat(this.pickerDeteFrom) + '以降'
      if (this.pickerDeteTo) {
        this.conditions += (this.conditions && ' ')
        if (!this.pickerDeteFrom) this.conditions += '登録日＝'
        this.conditions += this.dateFormat(this.pickerDeteTo) + 'まで'
      }

      if (this.filterUseCountUp) this.conditions += (this.conditions && '／') + '利用回数＝' + this.filterUseCountUp + '回以上'
      if (this.filterUseCountDown) {
        this.conditions += (this.conditions && ' ')
        if (!this.filterUseCountUp) this.conditions += '利用回数＝'
        this.conditions += this.filterUseCountDown + '回以下'
      }
    },

    //フィルタークリア
    clearFilter() {
      this.pickerDeteFrom = ''
      this.pickerDeteTo = ''
      this.filterUseCountUp = ''
      this.filterUseCountDown = ''
      this.whereCustomerId = ''
      this.whereName = ''
      this.wherePhone = ''

      this.conditions = ''
      this.customers = []

      //クエリ文字列無しでリロード
      if (Object.keys(this.$route.query).length) {
        this.$router.push({ name: this.$route.name })
      }
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //顧客データ取得
    //**************************************************
    getCustomers() {
      this.customers.length = 0

      const query = {
        customerId: this.whereCustomerId,
        name: this.whereName,
        phone: this.wherePhone,
      }

      return this.adminApi.getReqWithAuth('customer/', query).then( results => {
        if (!results || !results.length) return

        results.map( row => {
          row.bookingRecords = []
          row.groups = []
          row.created_at = moment(row.created_at).format('YYYY-MM-DD')
        })

        this.customersSource = results
        // this.customers = results
      })
    },

    //**************************************************
    //顧客グループ取得
    //**************************************************
    setCustomerGroups() {
      return this.adminApi.getReqWithAuth('customer/group/').then( results => {
        if (!results || !results.length) return

        this.customersSource.map( customer => {
          const groups = results.filter( row => row.customer_id == customer.customer_id )
          if (groups !== undefined) customer.groups = [...groups]
        })
      })
    },

    //**************************************************
    //顧客データ登録＆更新 完了後コールバック
    //**************************************************
    updateCustomer(response, cameBackData) {
      if (cameBackData.update === 'basic') {
        if (cameBackData.index === undefined) {
          response.use_count = 0
          response.bookingRecords = []
          response.groups = []
          response.customer_id = Number(response.customer_id)

          //元配列を更新
          this.clearFilter()
          this.customersSource.unshift(response)
          this.whereCustomerId = response.customer_id
          this.filterCustomers()
        } else {
          response.created_at = this.customers[cameBackData.index].created_at
          response.use_count = this.customers[cameBackData.index].use_count
          response.bookingRecords = this.customers[cameBackData.index].bookingRecords
          response.groups = this.customers[cameBackData.index].groups
          response.customer_id = this.customers[cameBackData.index].customer_id
          this.customers.splice(cameBackData.index, 1, response)

          //元配列も更新
          const targetIndex = this.customersSource.findIndex( row => row.customer_id == response.customer_id )
          if (targetIndex > -1) this.customersSource.splice(targetIndex, 1, response)
        }
        this.$refs.formRegister.close()
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      }
       else if (cameBackData.update === 'group') {
        //顧客グループ更新
        this.customers[cameBackData.index].groups = response
      }
       else if (cameBackData.update === 'booking') {
        //利用回数更新
        this.customers[cameBackData.index].use_count += response
      }
       else if (cameBackData.update === 'deleteBooking') {
        //ダミー利用履歴削除
        this.customers[cameBackData.index].use_count -= response
      }
    },

    //**************************************************
    //顧客情報統合
    //**************************************************
    mergeCustomers(formData, cameBackData) {
      const apiPartial = 'customer/merge/' + cameBackData.mergeData.customer_id + '/and/' + cameBackData.deleteId

      this.adminApi.apiReqWithData('PUT', apiPartial, formData).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}

        //マージ側更新
        let targetIndex = this.customers.findIndex( row => row.customer_id == cameBackData.mergeData.customer_id )
        this.customers.splice(targetIndex, 1, cameBackData.mergeData)
        targetIndex = this.customersSource.findIndex( row => row.customer_id == cameBackData.mergeData.customer_id )
        this.customersSource.splice(targetIndex, 1, cameBackData.mergeData)

        //削除側更新
        targetIndex = this.customers.findIndex( row => row.customer_id == cameBackData.deleteId )
        this.customers.splice(targetIndex, 1)
        targetIndex = this.customersSource.findIndex( row => row.customer_id == cameBackData.deleteId )
        this.customersSource.splice(targetIndex, 1)
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formMerge.close() )
    },

    //**************************************************
    //顧客データ削除
    //**************************************************
    deleteCustomer(cameBackData) {
      const customerId = this.customers[cameBackData.index].customer_id

      this.adminApi.apiReqWithData('DELETE', 'customer/delete/' + customerId).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
        this.customers.splice(cameBackData.index, 1)

        //元配列も削除
        const targetIndex = this.customersSource.findIndex( row => row.customer_id == customerId )
        this.customersSource.splice(targetIndex, 1)
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    },

    //**************************************************
    //対象顧客へLINE送信
    //**************************************************
    pushLineMessage(message, cameBackData) {
      const apiPartial = 'customer/' + this.customers[cameBackData.index].customer_id + '/pushmessage/'
      const payload = JSON.stringify({ message: message })

      this.adminApi.apiReqWithData('POST', apiPartial, payload).then( response => {
        const msg = response.customer + '様へのメッセージを' + $literals.MESSAGE.successSendSubmit
        this.snackbar = {...{color:'success', message: msg, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => cameBackData.cbOnSccess() )
    },

    //**************************************************
    //利用履歴取得
    //**************************************************
    setBookingRecords(customer) {
      if (customer.bookingRecords.length) return

      this.loadingBookingRecords = true

      const query = {
        fromDate: '',
        toDate: '',
        customerId: customer.customer_id,
        bookerName: '',
        phone: '',
        castId: ''
      }

      this.adminApi.getReqWithAuth('booking/', query).then( results => {
        if (!results || !results.length) return

        customer.bookingRecords = [...results]
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loadingBookingRecords = false  )
    },
  }
}
</script>

<style scoped>
.v-expansion-panel-header {
  height: 35px !important;
  min-height: 35px !important;
}
.booking-record > .v-card {
  border: none;
}
.theme--light.v-list-item:nth-child(even),
.theme--light .booking-record:nth-child(even) > .v-card {
  background-color: var(--content-bg-diff);
}
.theme--dark.v-list-item:nth-child(even),
.theme--dark .booking-record:nth-child(even) > .v-card {
  background-color: var(--content-bg-dark-diff);
}
</style>
