<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="550"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="pl-0 mb-5">
            <h2>配信用顧客グループを作成</h2>
          </v-card-title>

          <icon-info icon="lightbulb-on-outline" class="ml-n3 mb-5" :square="true">
            グループ分けした顧客へLINE等の指定配信をする際に使われます。<br />
            作成したグループは「店舗基本設定」→「顧客グループ」メニューで確認できます。
          </icon-info>

          <v-row>
            <v-col>
              <p class="my-3 text-body-2 text--secondary">該当人数：{{ this.customers.length }}／LINE友だち数：{{ this.lineMemberCount }}</p>
            </v-col>
          </v-row>

          <v-form ref="form">
            <v-row>
              <v-col cols="12"
                v-if="!isAdd"
              >
                <v-text-field
                  v-model.trim="groupName"
                  label="新規作成するグループ名を入力"
                  counter="30"
                  :rules="[valiRules.max30]"
                ></v-text-field>
              </v-col>
              <v-col cols="12"
                v-else
              >
                <v-select
                  v-model="selectedGroups"
                  label="既存顧客グループから選択"
                  multiple chips
                  :items="customerGroups"
                  item-value="customer_group_id"
                  item-text="group_name"
                  item-color="primary"
                  :rules="[valiRules.requiredArray]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="end">
              <v-col cols="3">
                <p>もしくは</p>
              </v-col>
              <v-col cols="9">
                <v-switch class="mt-0"
                  v-model="isAdd"
                  inset
                  hide-details
                  label="既存の顧客グループに追加"
                ></v-switch>
              </v-col>
            </v-row>

            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                :disabled="!isAdd && existsGroupName"
                @click="formGroupSubmitted"
              >
                登録
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    customers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      groupName: '',
      selectedGroups: [],
      customerGroups: [],
      isAdd: false,
      existsGroupName: false,
      timerId: null,
      openForm: false,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    lineMemberCount() {
      let count = 0
      this.customers.map( row => {
        if (row.line_id) count++
      })
      return count
    },
  },

  watch: {
    groupName: function(now) {
      if (!now) return

      const vm = this
      this.timerId = setTimeout(function() {
        //1秒待って名前重複を確認
        vm.adminApi.getReqWithAuth('exists/customer-group/', {groupName: now}).then( response => {
          if (response > 0) {
            vm.existsGroupName = true
            vm.snackbar = {...{color:'warning', message: $literals.MESSAGE.infoNameExists, open: true}}
          } else {
            vm.existsGroupName = false
          }
        })
        .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })

        this.timerId = null
      }, 1000)
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getCustomerGroups()
  },

  //**************************************************
  // メソッド
  //**************************************************
  methods: {
    open(handdownData) {
      this.groupName = handdownData.conditions
      this.openForm = true
    },

    close() {
      this.selectedGroups.length = 0
      this.groupName = ''
      this.isAdd = false
      this.loading = false
      this.openForm = false
    },

    //
    // API req: 顧客グループ取得
    //
    getCustomerGroups() {
      return this.adminApi.getReqWithAuth('customer-group/').then( results => {
        if (!results || !results.length) return

        this.customerGroups = results
      })
    },

    //**************************************************
    //**************************************************
    //                    各サブミット
    //**************************************************
    //**************************************************
    //顧客グループ
    //**************************************************
    formGroupSubmitted() {
      if (!this.$refs['form'].validate() || (!this.isAdd && !this.groupName) || (this.isAdd && !this.selectedGroups.length)) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }
      if (!this.isAdd && this.existsGroupName) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNameExists, open: true}}
        return
      }

      this.loading = true

      const apiPath = 'create/customer-group/'
      const updateData = {group_name: this.groupName}
      const payload = JSON.stringify(updateData)
      const customerIds = this.customers.map( row => row.customer_id )

      if (!this.isAdd) {
        //顧客グループを作成
        this.adminApi.apiReqWithData('POST', apiPath, payload).then( response => {
          const groupPayload = JSON.stringify({ 'groupIds': [response.customer_group_id], 'customerIds': customerIds })

          //顧客をグループに追加
          this.adminApi.apiReqWithData('POST', 'customer/update/group/', groupPayload).then(() => {
            this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
          })
          .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
          .then(() => this.close() )
        })
        .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      }
      else {
        const groupPayload = JSON.stringify({ 'groupIds': this.selectedGroups, 'customerIds': customerIds })

        //顧客をグループに追加
        this.adminApi.apiReqWithData('POST', 'customer/update/group/', groupPayload).then(() => {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
        })
        .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
        .then(() => this.close() )
      }
    },
  }
}
</script>

<style scoped>
</style>
